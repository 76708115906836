import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="notfound">
      <section className="app-content">
        <div className="app-hero-wrapper">
          <div className="app-hero-background-image"></div>
          <div className="app-hero-background-overlay"></div>
          <div className="app-hero-content-center"></div>
          <div className="app-hero-content-bottom"></div>
        </div>

        <div className="app-content-content">          
            <h1>Sorry, we couldn't find that page.</h1>
            <a href="/" className="btn btn-primary button-primary-hero">
              Go back to the our careers
            </a>         
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
